import './home.styles.scss';
import React, { FC, useState } from 'react';
import { useEffect, useRef } from 'react';
import { CSSTransition } from 'react-transition-group';
import { MdClose } from 'react-icons/md';

import profilePicture from '../../assets/images/profile-picture.jpg';
// import githubLogo from '../../assets/icons/github.svg';
// import linkedInLogo from '../../assets/icons/linkedin.svg';
import css3Logo from '../../assets/icons/tech-stack/css3.svg';
// import dockerLogo from '../../assets/icons/tech-stack/docker.svg';
import figmaLogo from '../../assets/icons/tech-stack/figma.svg';
// import gcpLogo from '../../assets/icons/tech-stack/google-cloud-platform.svg';
import html5Logo from '../../assets/icons/tech-stack/html5.svg';
import jsLogo from '../../assets/icons/tech-stack/javascript.svg';
// import nestjsLogo from '../../assets/icons/tech-stack/nestjs.svg';
import nodejsLogo from '../../assets/icons/tech-stack/nodejs.svg';
import npmLogo from '../../assets/icons/tech-stack/npm.svg';
import reactLogo from '../../assets/icons/tech-stack/react.svg';
import reduxLogo from '../../assets/icons/tech-stack/redux.svg';
import sassLogo from '../../assets/icons/tech-stack/sass.svg';
import tsLogo from '../../assets/icons/tech-stack/typescript.svg';

const Home: FC = () => {
	const techStack = [
		{ logo: css3Logo, alt: 'CSS3' },
		// { logo: dockerLogo, alt: 'Docker' },
		{ logo: figmaLogo, alt: 'Figma' },
		// { logo: gcpLogo, alt: 'GCP' },
		{ logo: html5Logo, alt: 'HTML5' },
		// { logo: nestjsLogo, alt: 'NestJS' },
		{ logo: jsLogo, alt: 'JavaScript' },
		{ logo: nodejsLogo, alt: 'Node.js' },
		{ logo: npmLogo, alt: 'npm' },
		{ logo: reactLogo, alt: 'React' },
		{ logo: reduxLogo, alt: 'Redux' },
		{ logo: sassLogo, alt: 'SASS' },
		{ logo: tsLogo, alt: 'TypeScript' },
	];

	const [showPronunciation, setShowPronunciation] = useState(false);

	const tooltipRef = useRef<HTMLDivElement | null>(null);
	const nameRef = useRef<HTMLSpanElement>(null);

	const togglePronunciation = () => {
		setShowPronunciation(!showPronunciation);
	};

	const handleClickOutside = (event: MouseEvent) => {
		if (
			tooltipRef.current &&
			!tooltipRef.current.contains(event.target as Node) &&
			event.target !== nameRef.current
		) {
			setShowPronunciation(false);
		}
	};

	useEffect(() => {
		if (showPronunciation) {
			document.addEventListener('mousedown', handleClickOutside);
		} else {
			document.removeEventListener('mousedown', handleClickOutside);
		}

		return () => {
			document.removeEventListener('mousedown', handleClickOutside);
		};
	}, [showPronunciation]);

	return (
		<section id='home' className='home-section'>
			<div className='home-container'>
				<div className='content-container'>
					<div className='home-content'>
						<h1 className='home-title'>
							Software Engineer <span className='waving-hand'>👋</span>
						</h1>
						<p className='home-info'>
							Hi, I'm{' '}
							<span
								ref={nameRef}
								className='name-highlight'
								onMouseEnter={() => setShowPronunciation(true)}
								onClick={togglePronunciation}
								onMouseLeave={() => {
									if (window.innerWidth > 1024) {
										setShowPronunciation(false);
									}
								}}
							>
								Simone
							</span>{' '}
							Melidoni. A passionate Software Engineer based in Manchester,
							England.📍
						</p>
						<CSSTransition
							in={showPronunciation}
							timeout={300}
							classNames='fade'
							unmountOnExit
						>
							<div ref={tooltipRef} className='pronunciation-tooltip'>
								<MdClose
									size={24}
									className='close-icon'
									onClick={() => setShowPronunciation(false)}
								/>
								Pronounced as <b>see-mo-nee</b>
							</div>
						</CSSTransition>
					</div>
					<div className='icon-container'>
						<div className='icon-link-container'>
							<a
								href='https://github.com/smelidoni'
								target='_blank'
								rel='noopener noreferrer'
								className='icon-link github-icon'
								aria-label='GitHub'
							>
								<div className='icon'></div>
							</a>
						</div>
						<div className='icon-link-container'>
							<a
								href='https://www.linkedin.com/in/simone-melidoni/'
								target='_blank'
								rel='noopener noreferrer'
								className='icon-link linkedin-icon'
								aria-label='LinkedIn'
							>
								<div className='icon'></div>
							</a>
						</div>
					</div>
					<div className='techstack-container'>
						<h2 className='techstack-title'>Tech Stack</h2>
						<div className='techstack-icons'>
							{techStack.map((tech, index) => {
								let techName = tech.alt;
								if (techName === 'JavaScript' || techName === 'TypeScript') {
									techName = techName.replace('Script', '\nScript');
								}
								return (
									<div className='techstack-icon-container' key={index}>
										<img
											src={tech.logo}
											alt={tech.alt}
											className='techstack-icon'
										/>
										<span className='techstack-icon-name'>{techName}</span>
									</div>
								);
							})}
						</div>
					</div>
				</div>
				<img className='profile-picture' src={profilePicture} alt='Profile' />
			</div>
		</section>
	);
};

export default Home;
